import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import styled, { css } from "styled-components"
import { media } from "../styles/media"
import Image from "gatsby-image"
import Layout from "../components/Layout"
import PageHeaderMedia from "../components/PageHeaderMedia"
import { GoCommentDiscussion } from "react-icons/go"
import { IconContext } from "react-icons"

const IconComment = () => (
  <IconContext.Provider value={{ size: "30px", color: "#dadada" }}>
    <GoCommentDiscussion />
  </IconContext.Provider>
)

const StyledWrap = styled.div`
  width: 70%;
  margin: 100px auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  .memberLists {
    width: 48%;
    position: relative;
    margin-bottom: 250px;
    .faceMedia {
      position: relative;
      width: 100%;
      height: 90%;
      overflow: hidden;
      .gatsby-image-wrapper {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: 0.8s;
      }
    }
    .texts {
      background: white;
      position: absolute;
      background: white;
      width: 70%;
      top: 70%;
      left: 0;
      padding: 2.62em 0.62em 0.62em 0;
      h4 {
        font-size: 0.8em;
        color: ${({ theme }) => theme.colors.primary.green};
      }
      h5 {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        height: 6em;
      }
      p {
        font-size: 0.8em;
        color: gray;
        margin-bottom: 0.62em;
      }
      a {
        display: inline-flex;
        flex-flow: row wrap;
        width: 100%;
        align-items: center;
        padding: 0.62em 0;
        color: ${({ theme }) => theme.colors.primary.red};
        overflow: hidden;
        &:after {
          display: block;
          content: "";
          width: 100%;
          height: 1px;
          background: ${({ theme }) => theme.colors.primary.red};
          margin-top: 0.62em;
          transform: translateX(-120%);
          transition: 0.8s;
        }
        svg {
          margin-right: 0.62em;
        }
        &:hover {
          &:after {
            transform: translateX(0);
          }
        }
      }
    }
    a.dLink {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 9;
      &:hover {
        + .faceMedia .gatsby-image-wrapper {
          opacity: 0.7;
          transform: scale(1.05);
        }
      }
    }
  }
  ${() =>
    media.tablet(css`
      width: 90%;
      .memberLists {
        .faceMedia {
        }
        .texts {
          width: 90%;
          margin-left: 5%;
          padding: 1.62em 1.62em 0.62em;
          border: 1px solid gray;
          background: rgba(255, 255, 255, 0.8);
        }
      }
    `)}
  ${() =>
    media.sp(css`
      margin: 30px auto 50px;
      .memberLists .texts {
        width: 100%;
        margin: 0;
        border: none;
        padding: 0.62em 0 1.62em;
        top: 90%;
      }
      .memberLists {
        margin-bottom: 200px;
      }
    `)}
`

const VoiceOfEmp = () => {
  const data = useStaticQuery(query)
  return (
    <Layout>
      <PageHeaderMedia
        fluid={data.zoo.employeesListHead.empHeadMedia.childImageSharp.fluid}
        pageTitles={data.zoo.employeesListHead.empHeadTitle}
        pageText={data.zoo.employeesListHead.empHeadSubtitle}
      />
      <StyledWrap>
        {data.car.nodes.map((panda, index) => (
          <div className="memberLists" key={index}>
            <Link className="dLink" to={panda.slug + "/"}></Link>
            <div className="faceMedia">
              <Image fluid={panda.faceMedia.childImageSharp.fluid} />
            </div>
            <div className="texts">
              <h4>{panda.depName}</h4>
              <h5 dangerouslySetInnerHTML={{ __html: panda.catchCopy }} />
              <p>{panda.entryYear}年入社</p>
              <Link to={panda.slug + "/"}>
                <IconComment />
                INTERVIEW
              </Link>
            </div>
          </div>
        ))}
      </StyledWrap>
    </Layout>
  )
}

export const query = graphql`
  {
    zoo: strapiRecruitmentPage {
      employeesListHead {
        empHeadMediaAlt
        empHeadSubtitle
        empHeadTitle
        empHeadMedia {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    car: allStrapiVoiceOfTheEmployees {
      nodes {
        catchCopy
        companyName
        depName
        entryYear
        slug
        faceMedia {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`

export default VoiceOfEmp
